import {
	arrays,
	colors,
	http,
	logger,
	styles,
	ui,
	userApi,
} from '@beelday/common'
import { UserId } from 'common/types'
import { difference, forEach, keyBy, map, reduce } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { FC, useMemo, useState } from 'react'
import { useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { useJuno } from 'vcr/juno/juno-provider'
import { v4 as uuid } from 'uuid'
import { Config } from 'common/config'

const log = logger.create('devtools')

type JanusUser = {
	display: string
	id: string
	publisher: boolean
	talking: boolean
	node: string
}
type JanusNode = {
	id: string
	url: string
	publishing: boolean
	users: JanusUser[]
}
type State = {
	roomId?: string | null
	nodes?: JanusNode[]
}

type JanusError = {
	code: number
	reason: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const fetchJanusData = (url: string, secret: string, command: {}) =>
	fetch(url, {
		method: 'POST',
		body: JSON.stringify({
			janus: 'message_plugin',
			transaction: uuid(),
			admin_secret: secret,
			plugin: 'janus.plugin.videoroom',
			request: command,
		}),
	})
		.then(http.checkStatusAllowNotFound)
		.then(http.parseBody)

type Props = {
	pollInterval: number
}
export const DevToolsJanus: FC<Props> = ({ pollInterval }) => {
	const { juno } = useJuno()
	const [secret, setSecret] = useState(() =>
		localStorage.getItem('beelday_janus_admin_secret')
	)
	const [janusState, setJanusState] = useState<State>()
	const [janusErrors, setJanusErrors] = useState<JanusError[]>([])
	const [fetchingState, setFetchingState] = useState<
		'LOADING' | 'ERROR' | null
	>(null)

	const janusURL = useRef('')

	const updateSecret = (update: string) => {
		localStorage.setItem('beelday_janus_admin_secret', update)
		setSecret(update)
	}

	useEffect(() => {
		if (juno && secret) {
			const pollJanuses = async () => {
				setFetchingState('LOADING')
				const { publishNodes, subscribeNodes, roomId } = juno._getDebugData()
				const errors: JanusError[] = []

				const processNode = async (
					node: string,
					publishing: boolean
				): Promise<JanusNode> => {
					const url = new URL(node)
					const href = `https://${url.hostname}:${Config.janusAdminPort}/admin`
					janusURL.current = href
					const id = url.hostname.split('.')[0]
					const users: JanusUser[] = []
					await fetchJanusData(href, secret, {
						request: 'listparticipants',
						room: roomId,
					}).then(res => {
						if (res.janus === 'success') {
							forEach(res.response.participants, participant =>
								users.push({ ...participant, node })
							)
						} else {
							if (res.error?.code === 403) {
								log.warn('Auth failed, removing stored secret')
								setSecret(null)
								localStorage.removeItem('beelday_janus_admin_secret')
							}
							log.error(res.error)
							errors.push(
								res.error || { code: 666, reason: 'Could not parse error!' }
							)
						}
					})

					return { id, url: node, users, publishing }
				}
				const nodes = await Promise.all([
					...(publishNodes || []).map(node => processNode(node, true)),
					...(difference(subscribeNodes, publishNodes || []) || []).map(node =>
						processNode(node, true)
					),
				])
					.catch(e => {
						setFetchingState('ERROR')
						log.error('Failed to poll Janus', e)
						return [] as JanusNode[]
					})
					.finally(() => {
						if (errors.length) {
							setFetchingState('ERROR')
						} else {
							setFetchingState(null)
						}
					})

				setJanusState({ roomId, nodes })
				setJanusErrors(errors)
			}

			pollJanuses()
			const intervalId = setInterval(pollJanuses, pollInterval)
			return () => clearInterval(intervalId)
		}
	}, [juno, pollInterval, secret])

	const userIds: UserId[] = reduce(
		janusState?.nodes,
		(acc, node) => {
			arrays.pushAll(acc, node?.users.map(u => u.id) || [])
			return acc
		},
		[] as UserId[]
	)

	useFetchMissingProfiles(userIds || [])
	const profiles = useProfilesFor(userIds || [])
	const profilesById = useMemo(() => keyBy(profiles, 'id'), [profiles])

	if (!secret) {
		return (
			<ui.FlexColumn>
				<h3>Please provide Janus admin secret:</h3>
				<ui.PasswordInput
					onBlur={e => updateSecret(e.target.value)}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							updateSecret(e.currentTarget.value)
						}
					}}
				/>
			</ui.FlexColumn>
		)
	} else if (janusState) {
		return (
			<ui.FlexColumn>
				<ui.FlexRow style={{ alignItems: 'center' }}>
					<ui.FillSpace>
						<b>Janus Room:</b> {janusState.roomId}
					</ui.FillSpace>
					<small>
						{fetchingState === 'LOADING'
							? '⏲ Loading'
							: fetchingState === 'ERROR'
							? '☠️ Error'
							: null}
					</small>
				</ui.FlexRow>
				<div style={{ overflowY: 'auto', flex: 1 }}>
					<hr />
					{map(janusState.nodes, node => {
						return (
							<ui.FlexColumn key={node.url}>
								<div>
									<h3 css={styles.ellipsis} title={node.url}>
										{node.id}
										{node.publishing ? ' (publishing)' : ''}
									</h3>
									<ul>
										{node.users.map(user => (
											<li key={user.id}>
												{userApi.getDisplayName(profilesById[user.id]) ||
													user.id}
												&nbsp; (
												<b
													style={{
														color: user.publisher
															? colors.green
															: colors.lightGray,
													}}
												>
													P
												</b>
												&nbsp;
												<b
													style={{
														color: user.talking ? colors.red : colors.lightGray,
													}}
												>
													T
												</b>
												)
											</li>
										))}
									</ul>
								</div>
							</ui.FlexColumn>
						)
					})}
				</div>
				{janusErrors.length ? (
					<div>
						<b>Errors: </b>
						{janusErrors.map((e, i) => (
							<div key={i}>
								{e.code} - {e.reason}
							</div>
						))}
					</div>
				) : null}
				<JanusDebugger janusURL={janusURL.current} secret={secret} />
			</ui.FlexColumn>
		)
	} else {
		return null
	}
}

export default DevToolsJanus

type JanusDebuggerProps = {
	janusURL: string
	secret: string
}
export const JanusDebugger = ({
	janusURL,
	secret,
}: JanusDebuggerProps): JSX.Element => {
	const [noTimer, setNoTimer] = useState(5)

	const setNoMedia = useCallback(() => {
		const request = {
			janus: 'set_no_media_timer',
			no_media_timer: noTimer,
			transaction: randomString(12),
			admin_secret: secret,
		}

		return fetch(janusURL, {
			method: 'POST',
			headers: {
				contentType: 'application/json',
			},
			body: JSON.stringify(request),
		})
	}, [janusURL, noTimer, secret])

	const getJanusInfo = useCallback(() => {
		fetch(janusURL + '/info', {
			method: 'GET',
			headers: {
				contentType: 'application/json',
			},
		}).then(res => {
			if (res.status === 200) {
				res.json().then(data => {
					console.log('Janus info', data)
				})
			} else {
				console.error('Janus info error', res)
			}
		})
	}, [janusURL])

	return (
		<ui.RightDetailsPanel header={<ui.Title>Janus debugger</ui.Title>}>
			<ui.Input
				type="number"
				value={noTimer}
				onChange={e => setNoTimer(parseInt(e.currentTarget.value))}
			/>
			<button onClick={() => setNoMedia().then(getJanusInfo)}>
				SET NO MEDIA TIMER
			</button>
			<button onClick={getJanusInfo}>GET INFO</button>
		</ui.RightDetailsPanel>
	)
}

function randomString(len: number) {
	const charSet =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	let randomString = ''
	for (let i = 0; i < len; i++) {
		const randomPoz = Math.floor(Math.random() * charSet.length)
		randomString += charSet.substring(randomPoz, randomPoz + 1)
	}
	return randomString
}
